import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

import { initialInputSheet } from '../../assets/initial_input_sheet';
import { store } from '../../Store';
import { userApi } from '../../api';
import { userRoleMap } from '../../utils/maps';

export type CalibrationType = {
	state: 'error' | 'running' | 'success';
	last_complete: string;
	time_started: string | null;
} | null;

export type TopsisType = {
	state: 'error' | 'running' | 'success';
	last_complete: string;
	time_started: string | null;
} | null;

interface SelectedBuildingState {
	prop_name: string;
	prop_id: string;
	bem_response: null;
	// TODO: add typing for all input sheet values
	input_sheet: Record<string, any>;
	// TODO: add bem input typing
	bem_input: Record<string, any> | null;
	calibration: CalibrationType;
	group_name: string;
	region: string;
	city: string;
	prop_users: Record<string, any>[];
	can_submit: boolean;
	loading: boolean;
	created_at: string;
}

const initialState: SelectedBuildingState = {
	prop_name: '',
	prop_id: '',
	bem_response: null,
	input_sheet: initialInputSheet,
	bem_input: null,
	calibration: null,
	group_name: '',
	region: '',
	city: '',
	prop_users: [],
	can_submit: false,
	loading: false,
	created_at: '',
};

function mergeSheets(savedSheet: any, updatedSheet: any) {
	// Create a new merged sheet with the saved sheet as the base
	const mergedSheet = { ...savedSheet };

	// Update the merged sheet with keys from the updated sheet
	for (const key in updatedSheet) {
		if (updatedSheet.hasOwnProperty(key) && !savedSheet.hasOwnProperty(key)) {
			mergedSheet[key] = updatedSheet[key];
		}
	}

	return mergedSheet;
}

export const addSelectedBuildingAsync =
	(buildingData: SelectedBuildingState) => async (dispatch: Dispatch) => {
		try {
			const { userDetails } = store?.getState().user;
			let propUserFilter: userApi.PropUserFilter;

			if (userDetails.role === userRoleMap.Owner) {
				propUserFilter = {
					prop_id: buildingData.prop_id,
				};
			} else {
				propUserFilter = {
					user_id: userDetails.user_id,
				};
			}
			let propUsers = [];
			try {
				// Retrieve the list of prop_users from the response
				const propUsersResponse = await userApi.getPropUsers(propUserFilter);
				propUsers = propUsersResponse.data;
			} catch (error) {}

			const mergedSheet = mergeSheets(
				buildingData.input_sheet,
				initialInputSheet
			);

			// Assuming your API returns data in response.data
			const building = {
				...buildingData,
				input_sheet: mergedSheet,
				prop_users: propUsers,
				loading: false,
			};

			dispatch(addSelectedBuilding(building));
		} catch (error) {
			// Handle any errors here
			console.error('Error fetching data:', error);
		}
	};

const selectedBuildingSlice = createSlice({
	name: 'selectedBuilding',
	initialState,
	reducers: {
		setBuildingToLoading: (state, { payload }) => {
			const newObject = { ...payload, loading: true };
			return newObject;
		},
		addSelectedBuilding: (state, { payload }) => {
			return payload;
		},
		// Update the input sheet object with the new object received
		updateInputSheetWithObj: (state, { payload: newValues }) => {
			if (typeof newValues === 'object' && newValues !== null) {
				state.input_sheet = { ...state.input_sheet, ...newValues };
			}
			return state;
		},
		updateInputSheetVal: (state, { payload }) => {
			/// An array of strings (the list of keys at every level) is sent as the name
			// For example, if [a, b, c] is the name sent, then input_sheet[a][b][c] will be updated
			// val {any} of the corresponding value
			const { name, val } = payload;
			// If the key is a string, directly replace the value
			if (typeof name === 'string') {
				state.input_sheet[name] = val;
			}
			// If an array is sent, replace the value at the end of tree
			else if (Array.isArray(name)) {
				if (name.length) {
					let currentObj = state.input_sheet;
					// If any of the previous keys do not exist, create a key and assign it an empty object
					name.slice(0, -1).forEach((key) => {
						currentObj[key] = currentObj[key] || {};
						currentObj = currentObj[key];
					});
					// Assign the value for the final key
					const lastKey = name[name.length - 1];
					currentObj[lastKey] = val;
				}
			}
			return state;
		},
		resetSelectedBuilding: (state) => {
			return {
				prop_name: '',
				prop_id: '',
				bem_response: null,
				input_sheet: initialInputSheet,
				bem_input: null,
				calibration: null,
				group_name: '',
				region: '',
				city: '',
				prop_users: [],
				can_submit: false,
				loading: false,
				created_at: '',
			};
		},
	},
});

export const {
	setBuildingToLoading,
	addSelectedBuilding,
	updateInputSheetWithObj,
	updateInputSheetVal,
	resetSelectedBuilding,
} = selectedBuildingSlice.actions;

export default selectedBuildingSlice.reducer;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setAccessToken } from '../redux/auth/AuthSlice';
import { authApi } from '../api/';

export function WithAuth({ children }) {
	const dispatch = useDispatch();

	const accessToken = useSelector((state) => state.auth.accessToken);

	const checkTokenExpiration = React.useCallback(async () => {
		const { data } = await authApi.getSession();
		if (!data) {
			dispatch(setAccessToken(''));
			return;
		}
		let { session } = data;
		if (session?.user) {
			// Check if the token has expired
			const timeNow = Math.round(Date.now() / 1000);

			if (timeNow > session.expires_at) {
				// Token has expired, log the user out and redirect to login
				authApi.signOut();
			}
		}
	}, [dispatch]);

	useEffect(() => {
		// Call the function once on first render
		checkTokenExpiration();
		// Check token expiration every minute (you can adjust the interval as needed)
		const tokenCheckInterval = setInterval(checkTokenExpiration, 60000);
		return () => {
			clearInterval(tokenCheckInterval);
		};
	}, [checkTokenExpiration]);

	let url = decodeURIComponent(window.location.href.split('/#')[1]);

	let urlParams = new URLSearchParams(url);
	let type = urlParams.get('type') || '';

	//Workaround for the remote single use token being clicked by email security
	if (type === 'recovery' || type === 'signup' || type === 'invite') {
		window.location.href = url;
	} else {
		return accessToken ? children : <Navigate to="/login" />;
	}
}

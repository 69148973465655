import FileUpload from '../buildingDocuments/FileUpload';
import { ActionButtonGroup } from '../../CommonTemplates';
import { inputJsonKeys, energyDirectPageKeys } from './EnergyDirectOnboarding';

const allowedFileTypes = ['pdf', 'xls', 'xlsx', 'csv'];

export function UploadUtilityBill({
	inputData,
	bucketName,
	setShowSnackbar,
	updateEnergyDirectData,
	buildingName,
}) {
	const hasUploaded =
		inputData?.[inputJsonKeys.energyDirectUtilityBills]?.length > 0;

	return (
		<>
			<p>
				Please upload a utility bill from each of {buildingName}’s energy direct
				account. Once received, we’ll request you for access as a secondary
				user.
			</p>
			<div className="FileUploadContainer">
				<FileUpload
					documentName="Utility Bill"
					description="Please upload a utility bill from each of your energy direct accounts. We’ll request you for access as a secondary user using the web access code and account number."
					inputData={inputData}
					inputJsonKey={inputJsonKeys.energyDirectUtilityBills}
					bucketName={bucketName}
					filenamePrefix={'utility_bill'}
					setShowSnackbar={setShowSnackbar}
					allowedFileTypes={allowedFileTypes}
				/>
			</div>
			<ActionButtonGroup
				updateData={updateEnergyDirectData}
				pageNumber={energyDirectPageKeys.ACCOUNT_EXISTS}
				pageJsonKey={inputJsonKeys.energyDirectPageNumber}
				showSubmit={true}
				disableSubmit={!hasUploaded}
				onSubmit={() => {
					updateEnergyDirectData({
						[inputJsonKeys.energyDirectPageNumber]:
							energyDirectPageKeys.UTILITY_BILL_SUCCESS,
					});
				}}
			/>
		</>
	);
}
export function UploadConsumptionData({
	inputData,
	bucketName,
	setShowSnackbar,
	updateEnergyDirectData,
	buildingName,
}) {
	const hasUploaded =
		inputData?.[inputJsonKeys.energyDirectConsumptionData]?.length > 0;

	return (
		<>
			<p>
				Please upload {buildingName}’s consumption data of at least the past 1
				year.
			</p>
			<div className="FileUploadContainer">
				<FileUpload
					documentName="Consumption Data"
					description="Please upload your consumption data. The more data you provide, the better we can help you save energy."
					inputData={inputData}
					inputJsonKey={inputJsonKeys.energyDirectConsumptionData}
					bucketName={bucketName}
					filenamePrefix={'consumption_data'}
					setShowSnackbar={setShowSnackbar}
					allowedFileTypes={allowedFileTypes}
				/>
			</div>
			<ActionButtonGroup
				updateData={updateEnergyDirectData}
				pageNumber={energyDirectPageKeys.ACCOUNT_EXISTS}
				pageJsonKey={inputJsonKeys.energyDirectPageNumber}
				showSubmit={true}
				disableSubmit={!hasUploaded}
				onSubmit={() => {
					updateEnergyDirectData({
						[inputJsonKeys.energyDirectPageNumber]:
							energyDirectPageKeys.CONSUMPTION_DATA_SUCCESS,
					});
				}}
			/>
		</>
	);
}

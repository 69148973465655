export const states = [
	'Alabama (AL)',
	'Alaska (AK)',
	'Arizona (AZ)',
	'Arkansas (AR)',
	'California (CA)',
	'Colorado (CO)',
	'Connecticut (CT)',
	'Delaware (DE)',
	'Florida (FL)',
	'Georgia (GA)',
	'Hawaii (HI)',
	'Idaho (ID)',
	'Illinois (IL)',
	'Indiana (IN)',
	'Iowa (IA)',
	'Kansas (KS)',
	'Kentucky (KY)',
	'Louisiana (LA)',
	'Maine (ME)',
	'Maryland (MD)',
	'Massachusetts (MA)',
	'Michigan (MI)',
	'Minnesota (MN)',
	'Mississippi (MS)',
	'Missouri (MO)',
	'Montana (MT)',
	'Nebraska (NE)',
	'Nevada (NV)',
	'New Hampshire (NH)',
	'New Jersey (NJ)',
	'New Mexico (NM)',
	'New York (NY)',
	'North Carolina (NC)',
	'North Dakota (ND)',
	'Ohio (OH)',
	'Oklahoma (OK)',
	'Oregon (OR)',
	'Pennsylvania (PA)',
	'Rhode Island (RI)',
	'South Carolina (SC)',
	'South Dakota (SD)',
	'Tennessee (TN)',
	'Texas (TX)',
	'Utah (UT)',
	'Vermont (VT)',
	'Virginia (VA)',
	'Washington (WA)',
	'West Virginia (WV)',
	'Wisconsin (WI)',
	'Wyoming (WY)',
];

export const PAGE_KEYS = {
	// TODO [SET-1435]: Uncomment along with the EE take-off
	// Envelope data
	// DRONE_LOGISTICS: 0,
	// Energy Data
	ENERGY_DIRECT_ACCESS: 0,
	ENERGY_STAR_ACCESS: 1,
	// Design and typical usage
	BUILDING_DOCUMENTS: 2,
	BUILDING_DETAILS: 3,
	// Guided Session
	EXPERT_REVIEW: 4,
};

export const buildingDocumentsDisplayName = {
	MEP: 'Mechanical schedule drawings',
	ELEVATIONS: 'Elevations of all sides of building',
	WATER_BILL: 'Water bill for the past 12 months',
	CONTROL_DRAWING: 'Control drawings',
	FLOOR_PLANS: 'Floor plans of below grade area',
	STRUCTURAL_SHOP: 'Structural shop drawings',
	SECTION_DETAIL_FACADE: 'Section detail of facade',
	GLAZING_SHOP: 'Glazing shop documents',
};

export const buildingDocumentsFilenamePrefix = {
	MEP: 'MEP_drawings',
	ELEVATIONS: 'elevations',
	WATER_BILL: 'water_bill',
	CONTROL_DRAWING: 'control_drawing',
	FLOOR_PLANS: 'floor_plans_below_grade',
	STRUCTURAL_SHOP: 'structural_shop',
	SECTION_DETAIL_FACADE: 'section_detail_facade',
	GLAZING_SHOP: 'glazing_shop',
};

export const buildingDocumentsJsonKey = {
	MEP: 'mepDrawingsFile',
	ELEVATIONS: 'elevationsFile',
	WATER_BILL: 'waterBillFile',
	CONTROL_DRAWING: 'control_drawing',
	FLOOR_PLANS: 'floorPlansFile',
	STRUCTURAL_SHOP: 'structuralShopFile',
	SECTION_DETAIL_FACADE: 'sectionDetailFacadeFile',
	GLAZING_SHOP: 'glazingShopFile',
};

export const buildingDocumentsDescriptions = {
	MEP: 'Drawings that are used for the coordination and installation of the mechanical, electrical, and plumbing aspects of the building.',
	ELEVATIONS:
		'Please submit drawings that outline a view of the building seen from one side, a flat representation of one façade along with its dimensions.',
	WATER_BILL: 'Please upload water bills for the past 12 months.',
	CONTROL_DRAWING:
		'These are technical drawings that illustrate (typically as a block diagram) the control systems and components used.',
	FLOOR_PLANS:
		"We seek a drawing that shows the layout of the building's below grade area along with specific dimensions of the rooms and wall lengths.",
	STRUCTURAL_SHOP:
		'These are plans that outline the installation of windows in a building as it pertains to material and dimensions.',
	SECTION_DETAIL_FACADE:
		"These are detailed plans that translate the design's intent of curtain walls as it pertains to the materials and dimensions.",
	GLAZING_SHOP:
		'These are plans that outline the installation of windows in a building as it pertains to material and dimensions.',
};

export const requiredBuildingDocuments = [
	buildingDocumentsJsonKey.MEP,
	buildingDocumentsJsonKey.ELEVATIONS,
	buildingDocumentsJsonKey.WATER_BILL,
	buildingDocumentsJsonKey.CONTROL_DRAWING,
];

import axiosInstance from './axiosInstance';
import { ApiResponse } from './types';

type GroupFilter = {
	group_name?: string;
};

type GroupData = {
	percentage_difference: string;
	target_metric: string;
	benchmark_year: number;
	target_year: number;
};

export const getGroups = async (
	filter: GroupFilter
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.get('/v2/admin/groups', {
			params: filter || {},
		});

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const createGroup = async (data: {}): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.post('/v2/admin/group', data);
	return response.data;
};

export const checkGroupAvailability = async (
	filter: GroupFilter
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get('/v2/admin/groups/availability', {
		params: filter,
	});

	return response.data.data;
};

export const updateGroupPortfolioMetrics = async (
	groupId: string,
	groupData: GroupData
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.put(
			`/v2/g/${groupId}/portfolio-metrics`,
			groupData
		);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getGroupData = async (
	groupId: string
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.get(`/v2/g/${groupId}/group-data`);
		return response.data;
	} catch (error) {
		throw error;
	}
};

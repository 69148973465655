import axiosInstance from './axiosInstance';
import { ApiResponse } from './types';

export const getTakeoffData = async (
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(`/v2/p/${propertyId}/ee/`);

	return response.data;
};

export const getAnomalyData = async (
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/anomaly-data`
	);

	return response.data;
};

export const getModelLoader = async (
	propertyId: string,
	modelType: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/model-loader`,
		{
			params: { model_type: modelType },
		}
	);

	return response.data;
};

export const getImagesData = async (
	data: {} = {},
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/images`,
		// points to new v2 endpoint
		{
			params: { data: JSON.stringify(data) },
		}
	);

	return response.data.data;
};

export const getOverviewImage = async (
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/overview-image`
	);

	return response.data;
};

export const getPCAReport = async (
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/download-pca-report`
	);

	return response.data;
};

import { formatString } from '../../../utils';
import { uploadFile, deleteFile } from '../../../api/awsApi';

export const S3_BUCKET = process.env.REACT_APP_aws_bucketName;
export const REGION = process.env.REACT_APP_aws_region;

export const MAX_FILE_SIZE = 300;
export const DEFAULT_ALLOWED_FILES = ['pdf'];
export const allFileTypes: Record<string, string> = {
	csv: 'text/csv',
	xls: 'application/vnd.ms-excel',
	xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	pdf: 'application/pdf',
};
export const fileTypeErrorMessage =
	'You have uploaded a file of type {}. Please upload a {} file.';

export const maxFileSizeErrorMessage =
	'File size exceeds the maximum limit of {}MB.';

export const URL_EXPIRATION_TIME = 3600;

type FileListItem = {
	name: string;
	url: string;
	awsFileName: string;
};

export const defaultFile: FileListItem = {
	name: '',
	url: '',
	awsFileName: '',
};

type FileUploadItem = {
	file: File;
	name: string;
	url: string;
	key: string;
};

export const validateFiles = (
	files: File[],
	allowedFileTypes: string[],
	maxFileSize: number
): {
	isValid: boolean;
	error: {
		file: File;
		message: string;
	} | null;
} => {
	const result = {
		isValid: true,
		error: null as { file: File; message: string } | null,
	};

	for (let i = 0; i < files.length; i++) {
		const file = files[i];
		const fileType = file.type;
		const fileSize = file.size;

		if (
			!allowedFileTypes.some(
				(type) => fileType === allFileTypes[type as keyof typeof allFileTypes]
			)
		) {
			result.isValid = false;
			const message = formatString(
				fileTypeErrorMessage,
				fileType,
				allowedFileTypes.join('/ ').toUpperCase()
			);
			result.error = {
				file,
				message,
			};
			return result;
		}

		if (fileSize > maxFileSize * 1024 * 1024) {
			result.isValid = false;
			const message = formatString(maxFileSizeErrorMessage, maxFileSize);
			result.error = {
				file,
				message,
			};
			return result;
		}
	}

	return result;
};

export const getExtensionFromFileName = (fileName: String) => {
	const dotIndex = fileName.lastIndexOf('.');
	if (dotIndex === -1) {
		return ''; // Return empty string if no extension found
	}
	return fileName.slice(dotIndex + 1).toLowerCase();
};

export const getUniqueFileName = (
	fileList: FileListItem[],
	fileName: string
): string => {
	let uniqueFileName = fileName;
	let index = 1;

	if (!fileList || !fileList.length) {
		return fileName;
	}

	while (fileList.some((file) => file.name === uniqueFileName)) {
		const extensionIndex = fileName.lastIndexOf('.');
		if (extensionIndex !== -1) {
			uniqueFileName = `${fileName.slice(
				0,
				extensionIndex
			)}${index}${fileName.slice(extensionIndex)}`;
		} else {
			uniqueFileName = `${fileName}${index}`;
		}
		index++;
	}

	return uniqueFileName;
};

export const awsFileUploader = async (
	files: FileUploadItem[],
	progressSetter: React.Dispatch<React.SetStateAction<number>>,
	propertyId: string
): Promise<FileListItem[]> => {
	const uploadPromises = files.map(async (fileUploadItem) => {
		const formData = new FormData();
		formData.append('file', fileUploadItem.file);
		formData.append('awsFileName', fileUploadItem.key);

		const response = await uploadFile(propertyId, formData, progressSetter);

		if (response.status !== 201) {
			throw new Error('Failed to upload file');
		}

		const data = response.data.data;
		const uploadData: FileListItem = {
			url: data,
			name: fileUploadItem.name,
			awsFileName: fileUploadItem.key,
		};
		return uploadData;
	});

	try {
		const uploadResults = await Promise.all(uploadPromises);
		return uploadResults;
	} catch (error) {
		progressSetter(0);
		throw error;
	}
};

export const awsFileDelete = async (
	Keys: string | string[],
	progressSetter: React.Dispatch<React.SetStateAction<number>>,
	propertyId: string
): Promise<boolean> => {
	const deleteKeys = Array.isArray(Keys) ? Keys : [Keys];
	const totalKeys = deleteKeys.length;
	let deletedKeys = 0;

	const handleDeleteComplete = () => {
		deletedKeys++;
		const progress = Math.round((deletedKeys / totalKeys) * 100);
		progressSetter(progress);
	};

	const deletePromises = deleteKeys.map(async (key) => {
		try {
			const data = { key: key };
			await deleteFile(propertyId, data);

			handleDeleteComplete();
		} catch (error) {
			throw new Error(`Error deleting files: ${error}`);
		}
	});

	try {
		await Promise.all(deletePromises);
		// progressSetter(0);
		return true;
	} catch (error) {
		// Handle error
		progressSetter(0);
		return false;
	}
};

export const openFile = (url: string) => {
	window.open(url, '_blank', 'noopener,noreferrer');
};

import congrats from '../../../assets/illustrations/congrats.svg';
import { Button } from '@mui/material';
import styles from '../../../pages/InputSheet.module.scss';

export function CongratsTemplate({
	onClick,
	title,
	message,
	buttonText = 'Got it!',
}) {
	return (
		<div className={styles.savePopupContainer}>
			<div className={styles.savePopupTitleContainer}>
				<img src={congrats} alt="congrats" />
				<div className={styles.text}>
					<h2 className={styles.title}>{title}</h2>
					<p>{message}</p>
				</div>
			</div>
			<div className="DataSharingButtons">
				<Button variant="contained" onClick={onClick}>
					{buttonText}
				</Button>
			</div>
		</div>
	);
}
export function ActionButtonGroup({
	updateData,
	pageNumber,
	pageJsonKey,
	showSubmit = false,
	buttonText = 'Submit',
	disableSubmit,
	onSubmit,
}) {
	return (
		<div className="DataSharingButtons">
			<Button
				style={{ textDecoration: 'underline' }}
				onClick={() => {
					updateData({
						[pageJsonKey]: pageNumber,
					});
				}}>
				Back
			</Button>
			{showSubmit && (
				<Button variant="contained" onClick={onSubmit} disabled={disableSubmit}>
					{buttonText}
				</Button>
			)}
		</div>
	);
}
export function InstructionStep({ stepNumber, stepDescription }) {
	return (
		<div className="step">
			<span className="step-number">{stepNumber}</span>
			<span className="step-description">{stepDescription}</span>
		</div>
	);
}

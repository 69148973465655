import { buildingDocumentsJsonKey } from '../components/dashboard/dataSharing/const';
import { inputJsonKeys as energyDirectKeys } from '../components/dashboard/dataSharing/popups/energyDirect/EnergyDirectOnboarding';
import { inputJsonKeys as energyStarKeys } from '../components/dashboard/dataSharing/popups/energyStar/EnergyStarOnboarding';

// The default time schedule arrays
export const fullDaySchedule = ['00:00', '23:00'];
export const emptySchedule = [null, null];

// Some inputs are to be collected for each day of the week
export const dayTypes = [
	'Mondays',
	'Tuesdays',
	'Wednesdays',
	'Thursdays',
	'Fridays',
	'Saturdays',
	'Sundays',
];

// Default format to save which days have schedules same as Mondays
export const defaultScheduleSettings = {
	Tuesdays: true,
	Wednesdays: true,
	Thursdays: true,
	Fridays: true,
};

// To set the initial values for the 5 schedule inputs (These inputs get a value for each day of the week)
export const temperatureSetpointDefault = {};
export const temperatureSetpointTimeScheduleDefault = {};
export const internalHeatGainDefault = {};
export const internalHeatGainTimeScheduleDefault = {};
export const lightingTimeScheduleDefault = {};

// Initialize the values for each of the 7 days for each of the schedule inputs
dayTypes.forEach((day) => {
	// Initial temperature setpoints
	temperatureSetpointDefault[`occHeatSetPoint${day}`] = '';
	temperatureSetpointDefault[`unoccHeatSetPoint${day}`] = '';

	// Initial temperature setpoint schedules
	temperatureSetpointTimeScheduleDefault[
		`setPointTemperatureForHeatingOn${day}`
	] = emptySchedule;
	temperatureSetpointTimeScheduleDefault[
		`setPointTemperatureForCoolingOn${day}`
	] = emptySchedule;

	// Initial internal heat gain occupancy count and schedules
	internalHeatGainDefault[`peopleInBuildingOn${day}`] = '';
	internalHeatGainTimeScheduleDefault[`occupiedHours${day}`] = emptySchedule;

	// Lighting schedules for each zone
	lightingTimeScheduleDefault[`lightingScheduleOn${day}`] = [
		emptySchedule,
		emptySchedule,
	];
});

export const DefaultDataSharingTrackingItem = {
	BUILDING_DOCUMENTS: 0,
	ENERGY_DIRECT: 0,
	ENERGY_STAR: 0,
	BUILDING_DETAILS: 0,
	EXPERT_REVIEW: 0,
};
// TODO: add types to all the intitial input sheet values

export const initialInputSheet = {
	// These values will be filled when the building is intially created
	companyId: '',
	companyName: '',
	buildingName: '',
	city: '',
	isCopy: false,
	buildingCopy: '',
	hasOpenedBuildingInfo: false,
	// Track the progress of the data sharing sections
	dataShareProgressTracking: DefaultDataSharingTrackingItem,
	// Garage section file upload fields
	isGarageIncludedInUtilityBill: '',
	isSubmeterInParkingGarage: '',
	garageFile: { name: '', url: '' },
	// Documents fields
	...Object.values(buildingDocumentsJsonKey).reduce(
		(acc, key) => ({ ...acc, [key]: [] }),
		{}
	),
	// Energy Star fields
	[energyStarKeys.energyStarAccountNumber]: null,
	[energyStarKeys.energyStarPropertyNumber]: null,
	[energyStarKeys.isEnergyStarConnected]: false,
	[energyStarKeys.isEnergyStarShared]: false,
	[energyStarKeys.energyStarPageNumber]: 0,
	// Energy Direct fields
	[energyDirectKeys.energyDirectAccountExists]: null,
	[energyDirectKeys.energyDirectUtilityBills]: [],
	[energyDirectKeys.energyDirectConsumptionData]: [],
	[energyDirectKeys.energyDirectPageNumber]: 0,
	// Building Info fields
	yearBuilt: '', // number
	typeOfTerrain: '',
	totalFloorArea: '',
	commonAreaPercent: '', // number
	buildingPurpose: [],
	// Building Envelop fields
	buildingType: '',
	designLevel: '',
	severityWallIssues: '',
	severityRoofIssues: '',
	//Air Leakage field
	airLeakageLevels: {},
	// Lighting fields
	...lightingTimeScheduleDefault,
	lightingScheduleSettings: defaultScheduleSettings,
	percentageLightInstalled: {},
	occupancySensor: emptySchedule,
	lightingControlsAmount: [null, null],
	lightingControlsUsage: [null, null],
	lightingAfterHoursUsage: [null, null],
	brightnessLightInstalled: {},
	numFixturesPerFloor: '', // number
	// Building Energy Management System fields
	buildingEnergyManagementSys: '',
	buildingEnergyManagementPractices: [],
	// Solar Panel System fields
	photovoltaicSystem: '',
	pvPanelPeakPowerCoefficient: '',
	typeOfPhotovoltaic: '',
	pvPerformanceFactor: '',
	ventilationOfPhotovoltaic: '',
	orientationOfPhotovoltaic: '',
	angleOfPhotovoltaic: '',
	areaOfEachPanel: '', // number
	numberOfArrays: '',
	voltageSupplied: '',
	// Solar Water Heating System fields
	solarWaterHeatingSys: '',
	// orientationOfSolarWaterHeatingSys: '',
	areaOfEachHeatingPanel: '', // number
	numberOfHeatingArrays: '', // number
	angleOfSolarWaterHeatingSys: '',
	dhwPerDay: '',
	// Domestic Hot Water fields
	primarySourceOfHotWater: null,
	boilerType: '',
	dhwEfficiency: {
		value: '',
		type: 'COP',
	},
	dhwGallonsPerDay: '',
	distanceOfHotWaterComingFrom: '',
	typeOfGenerationSys: '',
	capacityOfGenerationSys: '',
	// Internal Heat Gain fields
	...internalHeatGainDefault,
	...internalHeatGainTimeScheduleDefault,
	internalHeatGainScheduleSettings: defaultScheduleSettings,
	setIfOccupiedOnSaturdays: null,
	setIfOccupiedOnSundays: null,
	equipmentPowerDensity: null,
	// Temperature Setpoint fields
	...temperatureSetpointDefault,
	...temperatureSetpointTimeScheduleDefault,
	temperatureHeatingScheduleSettings: defaultScheduleSettings,
	temperatureCoolingScheduleSettings: defaultScheduleSettings,
	minimumHeatingSetPoint: '',
	maximumCoolingSetPoint: '',
	// Garage Schedule fields
	garageEquipment: { implemented: null, data: [] },
	garagePercentageLightInstalled: {},
	totalGarageArea: null,
	isPlenumHeater: null,
	garageHeatKBTU: null,
	garageHeatSource: null,
	numberOfChargingStations: '',
	chargingPowerOfEVStation: '',
	chargingMake: '',
	chargingModel: '',
	garageFanHorsepower: '',
	// Save whether the user knows the total R-value
	ifTotalRValueAvailable: { wall: null, roof: null },
	// Roof Material fields
	areaOfRoof: '',
	R_value_roof: '',
	roof_layersData: [],
	// Wall Material fields
	areaOfWall: '',
	R_value_wall: '',
	wall_layersData: [],
	// Window Material fields
	U_value_window: '',
	solarHeatGainCoefficient: '',
	emissivity: '',
	overhang: '',
	fins: '',
	windowFinishing: [],
	// HVAC system fields
	hvacSystems: { 0: { motorSystems: { 0: {} } } },
	// New HVAC
	// Heating
	primarySourceOfHeating: null,
	heatingMaintenanceLevel: '',
	heatingPlantAge: '', //number
	boilerPlant: { implemented: null, data: [] },
	electricResistance: { implemented: null, data: [] },
	districtHeatingConnection: { implemented: null, data: [] },
	// Cooling
	primarySourceOfCooling: null,
	coolingMaintenanceLevel: '',
	coolingPLantAge: '', //number
	waterCooledChiller: { implemented: null, data: [] },
	airCooledChiller: { implemented: null, data: [] },
	dx: { implemented: null, data: [] },
	districtCoolingConnection: { implemented: null, data: [] },
	// Heat pumps
	airSourceHeatPump: { implemented: null, data: [] },
	waterSourceHeatPump: { implemented: null, data: [] },
	// Condensing
	coolingTower: { implemented: null, data: [] },
	// Distribution
	ahu: { implemented: null, data: [] },
	unitarySystems: { implemented: null, data: [] },
	hydronic: { implemented: null, data: [] },
	zoneTerminalUnits: { implemented: null, data: [] },
	swud: { implemented: null, data: [] },
	// Ventilation
	typeOfVentilation: null,
	ventilation: {},
	doasMau: { implemented: null, data: [] },
	erv: { implemented: null, data: [] },
	exhaust: { implemented: null, data: [] },
	// Pumps
	motorsServiced: '',
	motorsAvgAge: '',
	pumps: { implemented: null, data: [] },
	// Floor information fields
	numFloors: null,
	heightOfTypicalFloor: null,
	missingFloors: null,
	missingFloorsInfo: null,
	floorInformation: null,
	heightBetweenRoadAndSecondFloor: null,
	additionalFloorInformation: null,
	// Miscellaneous
	isDraft: true,
	isDataSharingComplete: false,
};

import { energyStarPageKeys, inputJsonKeys } from './EnergyStarOnboarding';
import '../../DataSharing.scss';
import { CongratsTemplate } from '../../CommonTemplates';

export function SuccessPopup({ onClose, buildingName }) {
	return (
		<CongratsTemplate
			onClick={onClose}
			title="Access received!"
			message={`Access to ENERGY STAR data for ${buildingName} is now enabled.`}
		/>
	);
}

export function AlreadyConnected({ updateEnergyStarData }) {
	return (
		<CongratsTemplate
			onClick={() => {
				updateEnergyStarData({
					[inputJsonKeys.energyStarPageNumber]:
						energyStarPageKeys.GET_PROPERTY_NUMBER,
				});
			}}
			title="Account connected!"
			message="Joulea is already connected with your ENERGY STAR account."
		/>
	);
}

export function AlreadyShared({ onClose, buildingName }) {
	return (
		<CongratsTemplate
			onClick={onClose}
			title="Property shared!"
			message={`Joulea already has access to ${buildingName}’s ENERGY STAR data.`}
		/>
	);
}

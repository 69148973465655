import { useState } from 'react';
import {
	Typography,
	List,
	ListItem,
	ListItemText,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Zoom,
	CircularProgress,
} from '@mui/material';
import {
	OpenInNew as OpenInNewIcon,
	Delete as DeleteIcon,
	QuestionMark as QuestionMarkIcon,
} from '@mui/icons-material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { BootstrapTooltip } from '../../../../inputSheet/DropdownWithDesc';
import {
	validateFiles,
	getExtensionFromFileName,
	getUniqueFileName,
	DEFAULT_ALLOWED_FILES,
	openFile,
	allFileTypes,
	MAX_FILE_SIZE,
	awsFileUploader,
	awsFileDelete,
} from '../../../../utils/fileUpload/fileHelpers';
import { requiredBuildingDocuments } from '../../const';
import { updateInputSheetWithObj } from '../../../../../redux/selectedBuilding/SelectedBuildingSlice';
import { useDispatch } from 'react-redux';

const FileUpload = ({
	documentName,
	description,
	propertyId,
	inputData,
	inputJsonKey,
	filenamePrefix,
	allowedFileTypes = DEFAULT_ALLOWED_FILES,
	setShowSnackbar,
}) => {
	const dispatch = useDispatch();
	const [expanded, setExpanded] = useState(true);
	const [fileProgress, setFileProgress] = useState(0);
	const required = requiredBuildingDocuments.includes(inputJsonKey);
	const supportedFileTypes = allowedFileTypes.join(', ').toUpperCase();
	const uniqueIdentifier = filenamePrefix.replace(/\s/g, '');
	const fileCount = inputData[inputJsonKey]?.length || 0;

	const handleToggle = () => {
		setExpanded(!expanded);
	};

	// Upload file to S3 and update the input sheet
	const handleFileUpload = async (event) => {
		const files = event.target.files;
		const { error } = validateFiles(files, allowedFileTypes, MAX_FILE_SIZE);
		if (error) {
			const { message } = error;
			setShowSnackbar(message, 'error');
			return;
		}

		const fileList = Array.from(files).map((file, index) => {
			const fileExtension = getExtensionFromFileName(file.name);
			let awsFileName = `${filenamePrefix}-${
				Date.now() + index
			}.${fileExtension}`;
			return {
				file: file,
				name: getUniqueFileName(
					inputData?.[inputJsonKey] ? inputData[inputJsonKey] : [],
					file.name
				),
				key: awsFileName,
				url: null,
			};
		});

		try {
			const results = await awsFileUploader(
				fileList,
				setFileProgress,
				propertyId
			);
			if (results) {
				dispatch(
					updateInputSheetWithObj({
						[inputJsonKey]: [...inputData[inputJsonKey], ...results],
					})
				);
				setFileProgress(0);
			}
		} catch (error) {
			setShowSnackbar(error.message, 'error');
		}
	};

	// Delete file from S3 and update the input sheet
	const handleFileDelete = async (_, file) => {
		const awsFileName = file.awsFileName;

		try {
			const deleted = await awsFileDelete(
				awsFileName,
				setFileProgress,
				propertyId
			);
			if (deleted) {
				dispatch(
					updateInputSheetWithObj({
						[inputJsonKey]: inputData[inputJsonKey].filter(
							(obj) => obj.awsFileName !== awsFileName
						),
					})
				);
				setFileProgress(0);
			}
		} catch (error) {
			setShowSnackbar(error.message, 'error');
		}
	};

	return (
		<div className="MultipleUpload">
			<Accordion expanded={expanded} onChange={handleToggle}>
				<AccordionSummary className="accordion-summary">
					<div className="summary">
						<FileTitle
							documentName={documentName}
							description={description}
							supportedFileTypes={supportedFileTypes}
							required={required}
						/>
						<FileUploadButton
							fileProgress={fileProgress}
							uniqueIdentifier={uniqueIdentifier}
							handleFileUpload={handleFileUpload}
							fileCount={fileCount}
							allowedFileTypes={allowedFileTypes}
						/>
					</div>
				</AccordionSummary>
				<AccordionDetails className="accordion-detail">
					<AccordionContents
						fileCount={fileCount}
						inputData={inputData}
						inputJsonKey={inputJsonKey}
						handleFileDelete={handleFileDelete}
					/>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default FileUpload;

function AccordionContents({
	fileCount,
	inputData,
	inputJsonKey,
	handleFileDelete,
}) {
	return (
		<div className="accordionContents">
			{fileCount > 0 && (
				<List dense={true}>
					{inputData?.[inputJsonKey] &&
						inputData[inputJsonKey]?.map((file, index) => (
							<ListItem key={index} style={{ height: '50px' }}>
								<div className="files">
									<ListItemText primary={`${file.name}`} />
									<div className="actions-container">
										<OpenInNewIcon
											sx={{
												cursor: 'pointer',
												color: '#004225',
											}}
											onClick={() => openFile(file.url)}
										/>
										<DeleteIcon
											sx={{
												cursor: 'pointer',
												color: '#E7836C',
											}}
											onClick={(event) => handleFileDelete(event, file)}
										/>
									</div>
								</div>
							</ListItem>
						))}
				</List>
			)}
		</div>
	);
}

function FileTitle({
	documentName,
	description,
	supportedFileTypes,
	required,
}) {
	return (
		<div className="container">
			<div className="header">
				<Typography className="document-name">
					{documentName}
					{required ? <span style={{ color: 'red' }}>*</span> : ''}
				</Typography>
				<BootstrapTooltip
					title={<div style={{ fontSize: '16px' }}>{description}</div>}
					arrow
					placement={'top'}
					TransitionComponent={Zoom}>
					<QuestionMarkIcon sx={{ color: '#004225', fontSize: '15px' }} />
				</BootstrapTooltip>
			</div>
			<Typography className="valid-filetypes">
				File types: <span className="types">{supportedFileTypes}</span>
			</Typography>
		</div>
	);
}

function FileUploadButton({
	fileProgress,
	uniqueIdentifier,
	handleFileUpload,
	fileCount,
	allowedFileTypes,
}) {
	return (
		<div className="file-upload-container">
			<div className="fileupload">
				{fileProgress === 0 ? (
					<span
						className="btn valid-file"
						onClick={(event) => {
							event.stopPropagation();
							document.getElementById(uniqueIdentifier).click();
						}}>
						<div className="uploadButton">
							<AddRoundedIcon />
							<span>UPLOAD</span>
						</div>
						<input
							type="file"
							name="fileInput"
							id={uniqueIdentifier}
							multiple
							value={''}
							onChange={handleFileUpload}
							accept={allowedFileTypes
								.map((type) => allFileTypes[type])
								.join(', ')}
							style={{ display: 'none' }}
						/>
					</span>
				) : (
					<div className="actions-container">
						<CircularProgress
							variant="determinate"
							value={fileProgress === 100 ? 99 : fileProgress}
						/>
						{fileProgress > 0 && (
							<span className="progress-number">
								{fileProgress === 100 ? 99 : fileProgress}%
							</span>
						)}
					</div>
				)}
			</div>
			{fileCount > 0 && (
				<Typography className="file-count">
					{fileCount} {fileCount > 1 ? 'files' : 'file'} uploaded
				</Typography>
			)}
		</div>
	);
}

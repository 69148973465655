import axiosInstance from './axiosInstance';
import { ApiResponse, CustomError } from './types';

export type PropertyFilter = {
	role: number;
	user_id?: string;
	group_id?: string;
	prop_name?: string;
	// Add more filter properties as needed
};

export const createProperty = async (
	name: string,
	region: string,
	city: string,
	inputSheet: {},
	group_id: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.post(`/v2/g/${group_id}/properties`, {
		name,
		region,
		city,
		inputSheet,
	});

	return response.data;
};

export const getPropertyList = async (
	group_id: string
): Promise<ApiResponse<any[]>> => {
	// Make the API call to get the list of properties
	const response = await axiosInstance.get(`/v2/g/${group_id}/properties`);

	return response.data;
};

export const getProperty = async (id: string): Promise<ApiResponse<any[]>> => {
	// Make the API call to get the list of properties
	const response = await axiosInstance.get(`/v2/p/${id}/property`);

	return response.data;
};

export const updateProperty = async (
	id: string,
	dataToUpdate: {}
): Promise<ApiResponse<any>> => {
	// Make the API call to update the property
	const response = await axiosInstance.put(
		`/v2/p/${id}/property`,
		dataToUpdate
	);

	return response.data;
};

export const addUserToProperty = async (
	propertyId: string,
	data: any
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.post(
			`/v1/properties/${propertyId}/users`,
			data
		);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const archiveProperty = async (
	propertyId: string,
	data: {}
): Promise<ApiResponse<any>> => {
	try {
		// Make the API call to update the property
		const response = await axiosInstance.delete(
			`/v1/archive-building/${propertyId}`,
			{ data }
		);

		if (response.status !== 204) {
			throw new CustomError(
				'Property could bd not Deleted/archived successfully',
				'DELETE_ERROR',
				response.status
			);
		}

		return response.data;
	} catch (error) {
		throw error;
	}
};
